/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react'
import { useLocation, matchPath, useHistory } from 'react-router'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
  Drawer,
  Divider,
  List,
  ListSubheader,
  Hidden,
  colors,
  TextField
} from '@material-ui/core'
import NavItem from 'src/components/NavItem'
import { useConfig } from './navConfig'
import isSuperAdmin from 'src/utils/isSuperAdmin'
import { setCompany, setHeadCompany, setMarketplaces } from '../../actions'
import { useTranslation } from 'react-i18next'
import api from '../../utils/api'
import {
  confirming, cooperativeProgram,
  creditCard,
  creditCardOrderToCash, crossBorder,
  dynamicDiscounting,
  evolvedPayByLink, reverseFactoring
} from '../../utils/typeOfPrograms'
import CryptoJS from 'crypto-js'

function calculateHash (string) {
  return CryptoJS.SHA256(string).toString()
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: '60.5vw'
  },
  desktopDrawer: {
    width: '14.5vw',
    top: 64,
    height: 'calc(100% - 64px)'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 0, 2),
    margin: theme.spacing(0),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    width: 30,
    height: 30
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  }
}))

// Funzione che renderizza gli elementi del menu laterale
function renderNavItems ({
  items, subheader, key, ...rest
}) {
  return (
    items && items.length > 0
      ? <List key={key} style={{ paddingTop: 0, paddingBottom: 0 }}>
        {subheader && <ListSubheader disableSticky style={{ paddingLeft: 0, paddingTop: 25, fontSize: 12, lineHeight: 2 }}>{subheader}</ListSubheader>}
        {items && items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
          []
        )}
      </List> : <span key={key} />
  )
}

// funzione che permette di renderizzare anche sotto-elementi nel menu laterale
function reduceChildRoutes ({
  acc, pathname, item, depth = 0
}) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={`${item.href}-${item.label}-${depth}-${acc.length}`}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
        isExternalUrl={item.isExternalUrl}
      />
    )
  }

  return acc
}
const addPassivePrograms = (navConfig, currentCompany) => {
  const passiveCycleItems = navConfig.newConfiguration.passiveCycle
  let skipManualUploadSection = false
  if (currentCompany.passivePrograms) {
    for (const program of currentCompany.passivePrograms) {
      if (program === dynamicDiscounting) {
        passiveCycleItems.items.push(navConfig.ddItem)
      }
      if (program === creditCard) {
        passiveCycleItems.items.push(navConfig.creditCardItem)
      }
      if (program === confirming) {
        passiveCycleItems.items.push(navConfig.confirmingItem)
      }
      if (program === reverseFactoring) {
        passiveCycleItems.items.push(navConfig.reverseItem)
      }
      if (program === crossBorder) {
        passiveCycleItems.items.push(navConfig.crossBorderPaymentsSection)
      }
      if (program === cooperativeProgram) {
        skipManualUploadSection = true
        passiveCycleItems.items.push(navConfig.cooperativeProgramSection)
      }
    }
    passiveCycleItems.items.push(navConfig.handleSuppliers)
    if (!skipManualUploadSection) {
      passiveCycleItems.items.push(navConfig.uploadInvoicesItem)
    }
    if (skipManualUploadSection) {
      passiveCycleItems.items.push(navConfig.requestReportSection)
    }
    // passiveCycleItems.items.push(navConfig.approveInvoices)
  }
  return passiveCycleItems
}
function NavBar ({
  openMobile,
  openDesktop,
  onMobileClose,
  className,
  ...rest
}) {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const session = useSelector((state) => state.session)
  const { t } = useTranslation('navBar')
  const navConfig = useConfig()

  // utilizzo i valori di tutte le aziende della azienda corrente con cui sto visualizzando la dashboard
  const { companies, currentCompany, user, headCompanies, currentHeadCompany } = session

  // Verifico se sto visualizzando come superAdmin
  const isSa = isSuperAdmin(session.user.email)

  // utilizzo Set per avere un array di companies uniche e non ripetute
  const getUniqueCompanies = (companies) => [...new Set(companies.map(company => company.uuid))].map(uuid => companies.find(currCompany => currCompany.uuid === uuid))
  let isCreditor = false
  let isOrderToCash = false
  // Funzione che mi permette di visualizzare il menu corretto per tutti i ruoli che un utente può avere
  const displayRoleMenu = () => {
    // Creo un array di ruoli dalle regole acl
    const permissions = user && user.role && user.role
      .filter(companyPermission => companyPermission.companyId === currentCompany.uuid)
      .map(permission => permission.role)

    // Includo divido tutti i permessi per categoria di utente
    const permissionsPerRole = permissions && permissions
      .reduce((obj, permission) => {
        if (permission.includes('Buyer')) {
          obj.buyer.push(permission)
        } else if (permission.includes('Supplier')) {
          obj.supplier.push(permission)
        } else if (permission.includes('CallCenter')) {
          obj.callcenter.push(permission)
        } else if (permission.includes('Creditor')) {
          obj.creditor.push(permission)
        } else if (permission.includes('OrderToCash')) {
          obj.orderToCash.push(permission)
        } else if (permission.includes('FunderPartner')) {
          obj.funder.push(permission)
        }
        return obj
      }, { buyer: [], supplier: [], callcenter: [], funder: [], creditor: [], orderToCash: [] })
    // controllo se sono Admin in per qualche categoria
    isCreditor = permissionsPerRole && permissionsPerRole.creditor.some(permission => permission.includes('Admin'))
    isOrderToCash = permissionsPerRole && permissionsPerRole.orderToCash.some(permission => permission.includes('Admin'))
    const isBuyerAdmin = permissionsPerRole && permissionsPerRole.buyer.some(permission => permission.includes('Admin'))
    const isFunderAdmin = permissionsPerRole && permissionsPerRole.funder.some(permission => permission.includes('Admin'))
    const isSupplierAdmin = permissionsPerRole && permissionsPerRole.supplier.some(permission => permission.includes('Admin'))
    const isBuyerManager = permissionsPerRole && permissionsPerRole.buyer.some(permission => permission.includes('Manager'))
    const isSupplierManager = permissionsPerRole && permissionsPerRole.supplier.some(permission => permission.includes('Manager'))
    const isBuyerOperator = permissionsPerRole && permissionsPerRole.buyer.some(permission => permission.includes('Operator'))
    const isSupplierOperator = permissionsPerRole && permissionsPerRole.supplier.some(permission => permission.includes('Operator'))

    // controllo se sono commerciale di callcenter
    const isCallcenterSalesperson = permissionsPerRole && permissionsPerRole.callcenter.some(permission => permission.includes('Salesperson'))
    // controllo se sono operatore di callcenter
    const isCallcenterOperator = permissionsPerRole && permissionsPerRole.callcenter.some(permission => permission.includes('Operator'))

    // In base ai ruoli compongo il menu con tutte le voci per ogni ruolo
    if (isFunderAdmin) {
      return [
        navConfig.funderPartner.admin
      ]
    } else if (isCallcenterSalesperson && isCallcenterOperator) {
      return [navConfig.callcenter.commercial, navConfig.callcenter.operator]
    } else if (isCallcenterOperator && !isCallcenterSalesperson) {
      return [navConfig.callcenter.operator]
    } else if (isCallcenterSalesperson && !isCallcenterOperator) {
      return [navConfig.callcenter.commercial]
    } else if ((isBuyerAdmin && isSupplierAdmin) || (isBuyerManager && isSupplierManager) || (isBuyerOperator && isSupplierAdmin)) {
      if (currentCompany && currentCompany.hasMultiplePrograms) {
        const passiveCycleItems = addPassivePrograms(navConfig, currentCompany)
        const activeCycleItems = navConfig.newConfiguration.activeCycle
        if (currentCompany.activePrograms) {
          for (const program of currentCompany.activePrograms) {
            if (program === creditCardOrderToCash) {
              activeCycleItems.items = activeCycleItems.items
                .concat(navConfig.orderToCashSection.items)
            }
            if (program === evolvedPayByLink) {
              activeCycleItems.items.push(navConfig.evolvedPayByLink)
            }
          }
        }
        return [
          passiveCycleItems,
          navConfig.buyer.admin,
          activeCycleItems,
          navConfig.integrationsItems,
          navConfig.supplier.support
        ]
      }
      const allItems = [
        navConfig.buyer.buyerAndSupplier,
        // navConfig.buyer.financial,
        // navConfig.buyer.technical,
        navConfig.supplier.supplierAndBuyer,
        // navConfig.supplier.invitation,
        navConfig.buyer.admin,
        navConfig.integrationsItems,
        navConfig.supplier.support
      ]
      if (currentCompany && currentCompany.isDebtor) {
        allItems.unshift(getOrderToCashItem(currentCompany))
      }
      return allItems
    } else if ((isBuyerAdmin && !isSupplierAdmin) || (isBuyerManager && !isSupplierManager)) {
      if (currentCompany && currentCompany.isDebtor) {
        return createOrderToCashDebtorNav(currentCompany)
      }
      if (currentCompany && currentCompany.hasMultiplePrograms) {
        let passiveCycleItems
        if (currentCompany.passivePrograms && currentCompany.passivePrograms.length > 0) {
          passiveCycleItems = addPassivePrograms(navConfig, currentCompany)
        } else {
          passiveCycleItems = navConfig.buyer.multiOptionBuyer
        }
        return [
          // navConfig.buyer.multiOptionBuyer,
          passiveCycleItems,
          navConfig.buyer.admin,
          navConfig.integrationsItems,
          navConfig.buyer.support
        ]
      }
      return [
        navConfig.buyer.common,
        navConfig.buyer.admin,
        navConfig.integrationsItems,
        navConfig.buyer.support
        // navConfig.buyer.financial
        // navConfig.buyer.technical
      ]
    } else if ((isSupplierAdmin && !isBuyerAdmin) || (isSupplierManager && !isBuyerManager)) {
      if (currentCompany && currentCompany.hasMultiplePrograms) {
        const activeCycleItems = navConfig.newConfiguration.activeCycle
        if (currentCompany.activePrograms) {
          for (const program of currentCompany.activePrograms) {
            if (program === creditCardOrderToCash) {
              activeCycleItems.items = activeCycleItems.items
                .concat(navConfig.orderToCashSection.items)
            }
            if (program === evolvedPayByLink) {
              activeCycleItems.items.push(navConfig.evolvedPayByLink)
            }
          }
        }
        return [
          activeCycleItems,
          navConfig.supplier.admin,
          navConfig.integrationsSupplierItems,
          navConfig.supplier.support,
          navConfig.supplier.financial,
          navConfig.supplier.technical
        ]
      }
      const common = navConfig.supplier.common
      if (isOrderToCash) {
        common.subheader = t('activeCycleBuyerLed')
      }
      return [
        common,
        navConfig.supplier.admin,
        navConfig.supplier.invitation,
        navConfig.integrationsSupplierItems,
        navConfig.supplier.support,
        navConfig.supplier.financial,
        navConfig.supplier.technical
      ]
    } else if (isSupplierOperator) {
      if (isBuyerOperator) {
        let passiveCycleItems
        if (currentCompany.passivePrograms && currentCompany.passivePrograms.length > 0) {
          passiveCycleItems = addPassivePrograms(navConfig, currentCompany)
        } else {
          passiveCycleItems = navConfig.buyer.multiOptionBuyer
        }
        return [
          passiveCycleItems,
          navConfig.supplier.supplierAndBuyer,
          navConfig.integrationsSupplierItems,
          navConfig.supplier.support
        ]
      } else {
        return [
          navConfig.supplier.common,
          navConfig.supplier.admin,
          navConfig.integrationsSupplierItems,
          navConfig.supplier.support
        ]
      }
    } else if (isBuyerOperator) {
      if (currentCompany && currentCompany.hasMultiplePrograms) {
        let passiveCycleItems
        if (currentCompany.passivePrograms && currentCompany.passivePrograms.length > 0) {
          passiveCycleItems = addPassivePrograms(navConfig, currentCompany)
        } else {
          passiveCycleItems = navConfig.buyer.multiOptionBuyer
        }
        return [
          // navConfig.buyer.multiOptionBuyer,
          passiveCycleItems,
          navConfig.buyer.support
        ]
      }
      return [
        navConfig.buyer.common,
        navConfig.buyer.support
      ]
    } else {
      // Se sono sia buyer che supplier compongo i menu con le voci di entrambi in base al mio ruolo
      const permissions = permissionsPerRole && permissionsPerRole.buyer.concat(permissionsPerRole.supplier).concat(permissionsPerRole.callcenter)
        .reduce((menu, acl) => {
          const [type, role] = acl.split(/(?=[A-Z])/).map(s => s.toLowerCase())
          const hasBuyerPermissions = type === 'buyer'
          const hasSupplierPermissions = type === 'supplier'

          if (!Object.prototype.hasOwnProperty.call(menu, 'buyer') && hasBuyerPermissions) {
            menu.buyer = [navConfig.buyer.common]
          }

          // Add supplier
          if (!Object.prototype.hasOwnProperty.call(menu, 'supplier') && hasSupplierPermissions) {
            menu.supplier = [navConfig.supplier.common, navConfig.supplier.invitation]
          }

          menu[type].push(navConfig[type][role])
          return menu
        }, [])

      const menuList = permissions ? Object.values(permissions).flat() : []

      return menuList
    }
  }
  const getOrderToCashItem = company => {
    const passiveCycleItems = navConfig.newConfiguration.orderToCashDebtor
    if (company.passivePrograms) {
      for (const program of company.passivePrograms) {
        if (program === creditCard) {
          passiveCycleItems.items.push(navConfig.creditCardItem)
        }
      }
      passiveCycleItems.items.push(navConfig.approveInvoices)
      passiveCycleItems.items.push(navConfig.orderToCashPaymentMethods)
      // passiveCycleItems.items.push(navConfig.yourSuppliersItem)
    }
    return passiveCycleItems
  }
  const createOrderToCashDebtorNav = company => {
    const passiveCycleItems = getOrderToCashItem(company)
    return [
      passiveCycleItems,
      navConfig.buyer.admin,
      navConfig.buyer.support
    ]
  }
  const handleHeadCompanyChange = ({ target }) => {
    const headCompanyId = target.value
    const newCurrentHeadCompany = headCompanies.filter(headCompany => headCompany.uuid === headCompanyId)[0]
    if (newCurrentHeadCompany) {
      dispatch(setHeadCompany(newCurrentHeadCompany))
    }
  }

  const handleChange = async ({ target }) => {
    const companyId = target.value
    const newCurrentCompany = companies.filter(company => company.uuid === companyId)[0]
    if (newCurrentCompany) {
      dispatch(setCompany(newCurrentCompany))
    }
    if (newCurrentCompany.isBuyer) {
      const newMarketplaces = await api.getResource('companies', { path: `/${newCurrentCompany.uuid}/marketplaces/list/forBuyer` })
      dispatch(setMarketplaces(newMarketplaces))
      if (history.location.pathname.includes('/marketplace/buyer/details') && newMarketplaces && newMarketplaces.length > 0) {
        history.push(`/marketplace/buyer/details/${newMarketplaces[0].marketplaceUuid}`)
      } else if (history.location.pathname.includes('/manageSuppliers') && newMarketplaces && newMarketplaces.length > 0) {
        history.push(`/manageSuppliers/${newMarketplaces[0].marketplaceUuid}`)
      }
      // console.log(history.location.pathname)
      // console.log(newMarketplaces)
    } else {
      history.push('/home')
    }
  }
  const isATrueSuperAdmin = [
    'b91ef9cffb37aa9f2e15273d405c9073e646db9777a891fb4ad8bdf7d1ac81c1',
    'a5a0548735c5a118c7f7b85a2f07d6a74e0f3cf93419b2cfcf1c43754205df62',
    'b3ad0c07fb81c755804ebe43f653b7446dc811424ea64dd3811f5d312c00f3d2'
  ].includes(calculateHash(session.user.email))
  // se sono superadmin richiamo direttamente il menu del superadmin
  const navMenu = isSa
    ? isATrueSuperAdmin
      ? navConfig.superAdmin
      : navConfig.paOperator
    : displayRoleMenu()

  if (isCreditor) {
    navMenu.push(navConfig.reverseDynamicSection)
  }
  if (isOrderToCash) {
    navMenu.unshift(navConfig.orderToCashSection)
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }

    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >

      {!isSa
        ? (
          <>
            {headCompanies && headCompanies.length > 0 ? (
              <div className={classes.profile}>
                <TextField
                  InputLabelProps={{ shrink: !!currentHeadCompany.name }}
                  className={classes.field}
                  fullWidth
                  label={t('headCompanyLabel')}
                  name='headCompany'
                  onChange={(event) => handleHeadCompanyChange(event)}
                  select
                  disabled={
                    history.location.pathname.includes('/marketplace/buyer/details/') ||
                    history.location.pathname.includes('/marketplace/details/') ||
                    history.location.pathname.includes('/manageSuppliers/') ||
                    history.location.pathname.includes('/suppliers')
                  }
                  SelectProps={{ native: true }}
                  value={currentHeadCompany.uuid}
                  variant='outlined'
                >
                  {headCompanies.map(headCompany => <option key={headCompany.uuid} value={headCompany.uuid}>{headCompany.name}</option>)}
                </TextField>
              </div>
            ) : null}
            <div className={classes.profile}>
              <TextField
                InputLabelProps={{ shrink: !!currentCompany.name }}
                className={classes.field}
                fullWidth
                label={t('companyLabel')}
                name='company'
                onChange={(event) => handleChange(event)}
                select
                // disabled={
                //   history.location.pathname.includes('/marketplace/buyer/details/') ||
                //   history.location.pathname.includes('/marketplace/details/') ||
                //   history.location.pathname.includes('/manageSuppliers/')
                // }
                SelectProps={{ native: true }}
                value={currentCompany.uuid}
                variant='outlined'
              >
                {getUniqueCompanies(companies).map(company => <option key={company.uuid} value={company.uuid}>{company.name}</option>)}
              </TextField>
            </div>
            <Divider />
          </>
        ) : ''}

      <nav className={classes.navigation}>
        {navMenu.map((list, index) => renderNavItems({
          items: list.items,
          subheader: list.subheader,
          pathname: location.pathname,
          key: `${list.subheader}-${index}`
        }))}
      </nav>
      {/* <Divider className={classes.divider} /> */}
      {/* <div className={classes.profile}> */}
      {/*  <Link */}
      {/*    component={RouterLink} */}
      {/*    to='/profile' */}
      {/*    variant='h5' */}
      {/*    color='textPrimary' */}
      {/*    underline='none' */}
      {/*  > */}
      {/*    <Avatar */}
      {/*      alt='Person' */}
      {/*      className={classes.avatar} */}
      {/*    > */}
      {/*      {getInitials(session.user.email)} */}
      {/*    </Avatar> */}
      {/*  </Link> */}
      {/*  <div className={classes.details}> */}

      {/*    <Link */}
      {/*      component={RouterLink} */}
      {/*      to='/profile' */}
      {/*      variant='h6' */}
      {/*      color='textPrimary' */}
      {/*      underline='none' */}
      {/*    > */}
      {/*      <Box width='140px'> */}
      {/*        <Typography noWrap component='div' variant='h6'> */}
      {/*          {session.user.email} */}
      {/*        </Typography> */}
      {/*      </Box> */}
      {/*    </Link> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  )

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor='left'
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor='left'
          classes={{
            paper: classes.desktopDrawer
          }}
          open={openDesktop}
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default NavBar
