const dynamicDiscounting = 'dynamic-discounting'
const confirming = 'confirming'
const reverseFactoring = 'reverse-factoring'
const creditCard = 'credit-card'
const evolvedPayByLink = 'evolved-pay-by-link'
const creditCardOrderToCash = 'order-to-cash-credit-card'
const crossBorder = 'cross-border-payments'
const cooperativeProgram = 'cooperative-program'
module.exports = {
  cooperativeProgram,
  reverseFactoring,
  crossBorder,
  dynamicDiscounting,
  confirming,
  creditCard,
  evolvedPayByLink,
  creditCardOrderToCash,
  passivePrograms: [reverseFactoring, dynamicDiscounting, confirming, creditCard, crossBorder, cooperativeProgram],
  activePrograms: [creditCardOrderToCash, evolvedPayByLink]
}
